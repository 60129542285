import { colors } from './colors';
import { elementColors } from './theme';

const editorColors = {
  almostBlack: '#181A1B',
  lightBlack: '#2F3336',
  almostWhite: '#E6E6E6',
  white: '#FFF',
  white10: 'rgba(255, 255, 255, 0.1)',
  black: '#000',
  black10: 'rgba(0, 0, 0, 0.1)',
  primary: '#1AB6FF',
  greyLight: '#F4F7FA',
  grey: '#E8EBED',
  greyMid: '#C5CCD3',
  greyDark: '#DAE1E9'
};

const theme = {
  codeComment: '#6a737d',
  codePunctuation: '#5e6687',
  codeNumber: '#e06c75',
  codeProperty: '#c08b30',
  codeTag: '#3d8fd1',
  codeString: 'rgb(106,135,89)',
  codeSelector: '#6679cc',
  codeAttr: '#c76b29',
  codeEntity: '#22a2c9',
  codeKeyword: '#e06c75',
  codeFunction: '#c678dd',
  codeStatement: '#22a2c9',
  codePlaceholder: '#3d8fd1',
  codeInserted: 'rgb(106,135,89)',
  codeImportant: '#c94922',

  blockToolbarBackground: editorColors.white,
  blockToolbarTrigger: editorColors.greyMid,
  blockToolbarTriggerIcon: editorColors.white,
  blockToolbarItem: editorColors.almostBlack,
  blockToolbarText: editorColors.almostBlack,
  blockToolbarHoverBackground: editorColors.greyLight,
  blockToolbarDivider: editorColors.greyMid,

  noticeInfoBackground: colors.tailwind.blue[300],
  noticeInfoText: editorColors.almostBlack,
  noticeAsideBackground: colors.custom.pewter[300],
  noticeAsideText: editorColors.almostBlack,
  noticeSummaryBackground: colors.custom.blueGray[500],
  noticeSummaryText: editorColors.white,

  background: elementColors.background,
  text: editorColors.almostBlack,
  code: editorColors.almostWhite,
  cursor: editorColors.black,
  divider: editorColors.greyMid,

  toolbarBackground: editorColors.lightBlack,
  toolbarHoverBackground: editorColors.black,
  toolbarInput: editorColors.white10,
  toolbarItem: editorColors.white,

  tableDivider: editorColors.greyMid,
  tableSelected: editorColors.primary,
  tableSelectedBackground: '#E5F7FF',

  quote: colors.custom.blue[500], // editorColors.greyDark,
  codeBackground: 'rgb(50,50,50)',
  codeBorder: editorColors.grey,
  horizontalRule: editorColors.greyMid,
  imageErrorBackground: editorColors.greyLight
};

export default theme;
