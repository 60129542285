import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const ActionDialog = ({ confirmButtonLabel, title, isOpen, closeHandler, actionHandler, children }) => {
  const confirmationHandler = () => {
    actionHandler();
    closeHandler();
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={confirmationHandler} color="primary" variant="contained" autoFocus>
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActionDialog.propTypes = {
  confirmButtonLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired
};

export default ActionDialog;

export const ConfirmationDialog = ({ confirmButtonLabel, message, title, isOpen, closeHandler, actionHandler }) => {
  return (
    <ActionDialog
      actionHandler={actionHandler}
      closeHandler={closeHandler}
      isOpen={isOpen}
      confirmButtonLabel={confirmButtonLabel}
      title={title}
    >
      <DialogContentText>{message}</DialogContentText>
    </ActionDialog>
  );
};
