import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snippet from './Snippet';
import { useDomainModel } from '../services/ModelContext';

const useStyles = makeStyles(() => ({
  grid: {
    padding: 0,
    height: '100vh',
    width: '100%',
    display: 'grid',
    gridGap: 0,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr'
  },
  editorFrame: {
    overflowY: 'scroll',
    padding: '16px 16px'
  },
  filterFrame: {
    borderBottom: `solid 1px #cdcdcd`
  },
  logo: {
    width: 175
  },
  editorList: {
    maxWidth: 1100,
    margin: '16px auto'
  }
}));

function SnippetView({ view, isNew }) {
  /*
  TODO: new vs edit, need on change handlers to get onchange into model, from snippet
  might be okay to start with local state in the snippet, so it doesn't redraw every edit
  maybe save should make changes? propagate for multiple views?
   */

  const classes = useStyles();
  const { state, services } = useDomainModel();

  const onCreate = (id, text, tags) => services.effects.createSnippet(view.viewId, text, tags);
  const onUpdate = (id, text, tags, dontUpdateTimestamp) =>
    services.effects.updateSnippet(view.viewId, text, tags, dontUpdateTimestamp);
  const onCancel = () => services.actions.cancelNewSnippet(view.viewId);
  const onDelete = () => services.effects.deleteSnippet(view.viewId);

  return (
    <div className={classes.grid}>
      <div className={classes.editorFrame}>
        <div className={classes.editorList}>
          <Snippet
            showStatus={services.actions.setStatus}
            user={state.user}
            isNew
            tagOptions={state.tags}
            snippet={view.snippet}
            saveSnippet={isNew ? onCreate : onUpdate}
            deleteSnippet={isNew ? onCancel: onDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default SnippetView;
