import React from 'react';
import PropTypes from 'prop-types';
import { ImpulseSpinner, WhisperSpinner } from 'react-spinners-kit';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from './style/colors';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: -50,
    marginTop: -50
  }
}));

export function FullPageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.center}>
        <WhisperSpinner frontColor={colors.custom.blue4[400]} loading size={200} />
      </div>
    </div>
  );
}

export function Loading({ isLoading }) {
  return <ImpulseSpinner frontColor={colors.custom.blue4[400]} loading={isLoading} size={40} />;
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired
};
