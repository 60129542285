import React from 'react';
import { connect } from '@appbaseio/reactivesearch/lib/utils';
// eslint-disable-next-line import/no-extraneous-dependencies
import { executeQuery } from '@appbaseio/reactivecore/lib/actions';
import IconButton from '@material-ui/core/IconButton';
import { VscRefresh } from 'react-icons/vsc';

function RefreshSearchButton({ view, execQuery }) {
  const onClick = () => execQuery(`${view.viewId}-searchResults`, true, true);
  return (
    <IconButton aria-label="save snippet" onClick={onClick}>
      <VscRefresh />
    </IconButton>
  );
}

const mapDispatchtoProps = (dispatch) => ({
  execQuery: (componentId, includeWatch, mustQuery) => dispatch(executeQuery(componentId, includeWatch, mustQuery))
});

const RefreshSearchButtonWithReactiveSearch = connect(null, mapDispatchtoProps)(RefreshSearchButton);

export default RefreshSearchButtonWithReactiveSearch;
