const ageFilters = [
  Object.freeze({ start: 'now-1d', end: 'now', label: 'Last day', itemId: 'last-day' }),
  Object.freeze({ start: 'now-7d', end: 'now', label: 'Last week', itemId: 'last-week' }),
  Object.freeze({ start: 'now-1M', end: 'now-7d', label: '1 week to 1 month', itemId: 'week-to-month' }),
  Object.freeze({ start: 'now-6M', end: 'now-1M', label: '1 month to 6 months', itemId: 'month-to-6-months' }),
  Object.freeze({ start: 'now-1y', end: 'now-6M', label: '6 months to 1 year', itemId: 'months-to-year' }),
  Object.freeze({ start: 'now-10y', end: 'now-1y', label: '> 1 year', itemId: 'more-than-year' })
];

const sortOptions = [
  {
    dataField: '_score',
    sortBy: 'desc',
    label: 'Relevance',
    itemId: 'relevance'
  },
  {
    dataField: 'updatedAt',
    sortBy: 'desc',
    label: 'Updated Recently',
    itemId: 'updated-recently'
  },
  {
    dataField: 'updatedAt',
    sortBy: 'asc',
    label: 'Updated Oldest',
    itemId: 'updated-oldest'
  },
  {
    dataField: 'createdAt',
    sortBy: 'desc',
    label: 'Created Recently',
    itemId: 'created-recently'
  },
  {
    dataField: 'createdAt',
    sortBy: 'asc',
    label: 'Created Oldest',
    itemId: 'created-oldest'
  }
];

export { ageFilters, sortOptions };
