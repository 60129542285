import React from 'react';
import PropTypes from 'prop-types';
import { MultiDropdownList } from '@appbaseio/reactivesearch';
import { makeStyles } from '@material-ui/core/styles';
import { filterDropDown } from './style/style-helper';

const useStyles = makeStyles((theme) => filterDropDown(theme));

export default function MultiDataFilter({
  componentId,
  dataField,
  title,
  showSearch,
  queryFormat = 'or',
  reactFilterIds,
  value,
  onChange

}) {
  const classes = useStyles();
  const react = queryFormat === 'or' ? reactFilterIds.filter((item) => item !== componentId) : reactFilterIds;
  return (
    <MultiDropdownList
      className={classes.selectWrapper}
      innerClass={{ select: classes.selectBox }}
      componentId={componentId}
      dataField={dataField}
      queryFormat={queryFormat}
      title=""
      placeholder={title}
      showSearch={showSearch}
      react={{
        and: react
      }}
      size={300}
      loader="Loading ..."
      value={value}
      onChange={onChange}
      URLParams
    />
  );
}
MultiDataFilter.defaultProps = {
  showSearch: true
};
MultiDataFilter.propTypes = {
  componentId: PropTypes.string.isRequired,
  dataField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showSearch: PropTypes.bool
};
