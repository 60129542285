import React from 'react';
import ReactiveComponent from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import TagsSelector from './TagsSelector';
import { reactFilterIds } from '../utils/filter-utils';
import { useDomainModel } from '../services/ModelContext';

function TagsFilter({ tagsInputRef, view }) {
  const { search, viewId } = view;
  const { services } = useDomainModel();

  function createTagOptions(aggregations) {
    return aggregations
      ? aggregations.tags.buckets.map((t) => ({ name: t.key, count: t.doc_count, label: `${t.key} (${t.doc_count})` }))
      : [];
  }

  const react = reactFilterIds(viewId).filter((item) => item !== `${viewId}-tags`);

  return (
    <ReactiveComponent
      componentId={`${viewId}-tags`}
      URLParams
      defaultQuery={() => ({
        size: 0,
        aggs: {
          tags: {
            terms: {
              size: 500,
              field: 'tags.raw'
            }
          }
        }
      })}
      customQuery={(props) => {
        if (props) {
          const selectedValue = search.tags;
          if (selectedValue && selectedValue.length > 0) {
            const query = { bool: { must: [] } };
            selectedValue.forEach((val) => {
              query.bool.must.push({
                term: {
                  'tags.raw': val
                }
              });
            });
            return { query };
          }
        }
        return null;
      }}
      react={{
        and: react
      }}
      render={({ setQuery, aggregations }) => {
        function onSelectionChange(selection) {
          const query = { bool: { must: [] } };
          selection.forEach((val) => {
            query.bool.must.push({
              term: {
                'tags.raw': val
              }
            });
          });
          setQuery({ query, value: selection });
          services.actions.updateSearch(view.viewId, 'tags', selection);
        }

        return (
          <TagsSelector
            selected={search.tags}
            onChange={onSelectionChange}
            options={createTagOptions(aggregations)}
            ref={tagsInputRef}
            size="medium"
            variant="outlined"
          />
        );
      }}
    />
  );
}
export default TagsFilter;
