import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { createDefaultState, createServicesForContext, reducer } from './model';

const StateContext = createContext();
const ServicesContext = createContext();

function DomainModelProvider({ children, initialState, overrides, getCredentials }) {
  const startingState = initialState || createDefaultState(overrides);
  const [state, dispatch] = useReducer(reducer, startingState);

  const getCurrentState = () => state;

  const services = createServicesForContext(getCurrentState, dispatch, getCredentials);
  return (
    <StateContext.Provider value={state}>
      <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>
    </StateContext.Provider>
  );
}

DomainModelProvider.propTypes = {
  initialState: PropTypes.objectOf(PropTypes.any)
};

function useDomainModel() {
  const state = useContext(StateContext);
  const services = useContext(ServicesContext);
  if (state === undefined || services === undefined) {
    throw new Error('useDomainModel must be used within DomainModelProvider');
  }
  return { state, services };
}

export { DomainModelProvider, useDomainModel };
