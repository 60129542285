import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { saveAs } from 'file-saver';
import apiClient from '../services/api-client';
import Auth from '../services/auth';

function UserMenu({ anchorEl, onClose, getCredentials }) {
  async function onDownload() {
    try {
      const bookmarks = await apiClient.fetchSnippetsForExport(getCredentials);

      const json = JSON.stringify(bookmarks, null, 2);
      const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });

      const now = moment().format('MMDDYY');
      saveAs(blob, `bookmarks-${now}.json`);
      console.log('exported', `bookmarks-${now}.json`);
    } catch (ex) {
      console.error(ex);
    }
    onClose();
  }
  async function onSignOut() {
    await Auth.signOut();
    onClose();
  }

  return (
    <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={onDownload}>Download Snippets</MenuItem>
      <MenuItem onClick={onSignOut}>Sign Out</MenuItem>
    </Menu>
  );
}
export default UserMenu;
