import shortid from 'shortid';
import ApiClient from './api-client';
import Factory from './model-factory';
import { ageFilters } from './constants';

function createDefaultState(overrides) {
  return {
    user: null,
    snippets: {},
    virtualPages: [],
    currentViewId: null,
    views: [],
    tags: [],
    drawerOpen: false,
    status: Factory.newStatus(),
    ...overrides
  };
}

function reducer(state, action) {
  return action.handle(state);
}

function actionFor(handler, ...args) {
  if (args !== undefined) return { handle: handler.bind(null, ...args) };
  return { handle: handler };
}

function mergeState(args) {
  const merge = (newState, state) => ({ ...state, ...newState });
  return actionFor(merge, args);
}

function addNewView(view, state) {
  const { views } = state;
  views.push(view);
  return views;
}

function updateSnippetInSnippets(snippets, snippet) {
  const { snippet_id } = snippet;
  const existingSnippet = snippets[snippet_id];
  const oldVersion = existingSnippet && existingSnippet._version ? existingSnippet._version : 0;
  if (!snippet._version || !snippet._version <= oldVersion) snippet._version = oldVersion + 1;

  snippets[snippet_id] = snippet;
  return snippet._version;
}

function findView(views, viewId) {
  return views.find((v) => v.viewId === viewId);
}

function findViewIndex(views, viewId) {
  return views.findIndex((v) => v.viewId === viewId);
}

async function fetchTags(getCurrentState, actions, dispatch, getCredentials) {
  try {
    const tags = await ApiClient.fetchTags(getCredentials);
    dispatch(mergeState({ tags: tags.map((t) => ({ ...t, label: t.name })) }));
  } catch (ex) {
    actions.setStatus(`Failed to load tags: ${ex.message}`, true);
  }
}
async function fetchVirtualPages(getCurrentState, actions, dispatch, getCredentials) {
  try {
    const virtualPages = await ApiClient.fetchVirtualPages(getCredentials);
    dispatch(mergeState({ virtualPages }));
  } catch (ex) {
    actions.setStatus(`Failed to load virtual pages: ${ex.message}`, true);
  }
}

async function buildViewForVirtualPage(state, virtualPageId, getCredentials) {
  let record = state.virtualPages.find((vp) => vp.virtual_page_id === virtualPageId);
  if (!record) {
    record = await ApiClient.fetchVirtualPage(virtualPageId, getCredentials);
    if (!record) return null;
  }

  const { name, virtual_page_id, updated_at, type, snippet_ids, ...rest } = record;
  const virtualPage = { name, virtual_page_id, updated_at, type, snippet_ids };
  const search = { ...rest };

  return Factory.newView(shortid.generate(), virtualPage.name, 'virtual-page', { virtualPage, snippets: [], search });
}

async function openVirtualPageInView(virtualPageId, getCurrentState, actions, dispatch, getCredentials) {
  const state = getCurrentState();
  const newView = await buildViewForVirtualPage(state, virtualPageId, getCredentials);
  if (!newView) return;
  const views = addNewView(newView, state);
  dispatch(mergeState({ views, currentViewId: newView.viewId }));
}

async function saveVirtualPage(viewId, name, getCurrentState, actions, dispatch, getCredentials) {
  const { views, virtualPages } = getCurrentState();
  const view = findView(views, viewId);
  try {
    if (view.type !== 'search' && view.type !== 'virtual-page') return;

    view.isBusy = true;
    dispatch(mergeState({ views }));
    const isCreate = Boolean(view.type === 'search');
    let response;
    if (isCreate) response = await ApiClient.createVirtualPage(name, 'search', view.search, getCredentials);
    else {
      const { virtualPage } = view;
      virtualPage.name = name;
      response = await ApiClient.updateVirtualPage(
        virtualPage.virtual_page_id,
        virtualPage,
        view.search,
        getCredentials
      );
    }
    const virtualPage = response.data;
    view.virtualPage = virtualPage;
    view.type = 'virtual-page';
    view.isBusy = false;
    view.name = virtualPage.name;
    if (isCreate) virtualPages.push(virtualPage);
    else fetchVirtualPages(getCurrentState, actions, dispatch, getCredentials);
    dispatch(mergeState({ views, status: Factory.newStatus(true, 'Created Virtual Page', false) }));
  } catch (ex) {
    view.isBusy = false;
    dispatch(mergeState({ views, status: Factory.newStatus(true, ex.message, true) }));
  }
}
async function deleteVirtualPage(viewId, getCurrentState, actions, dispatch, getCredentials) {
  const { views } = getCurrentState();
  const index = findViewIndex(views, viewId);
  const view = views[index];
  try {
    if (!view || view.type !== 'virtual-page') return;

    view.isBusy = true;
    dispatch(mergeState({ views }));

    const { virtual_page_id } = view.virtualPage;
    await ApiClient.deleteVirtualPage(virtual_page_id, getCredentials);
    views.splice(index, 1);
    dispatch(mergeState({ views, status: Factory.newStatus(true, 'Deleted Virtual Page', false) }));
    fetchVirtualPages(getCurrentState, actions, dispatch, getCredentials);
  } catch (ex) {
    view.isBusy = false;
    dispatch(mergeState({ views, status: Factory.newStatus(true, ex.message, true) }));
    actions.setStatus(ex.message, true);
  }
}
function startNewSnippet(state) {
  const newView = Factory.newView(shortid.generate(), 'new...', 'new-snippet', { snippet: Factory.newSnippet() });
  return { ...state, views: addNewView(newView, state), currentViewId: newView.viewId };
}

function buildNameFromSnippet(snippet) {
  const { text } = snippet;
  if (!text) return snippet.snippet_id;

  return `${text.substring(0, 10)}...`;
}

async function createSnippet(viewId, text, tags, getCurrentState, actions, dispatch, getCredentials) {
  const { views, snippets } = getCurrentState();
  const view = findView(views, viewId);
  try {
    if (view.type !== 'new-snippet') return;

    view.isBusy = true;
    dispatch(mergeState({ views }));

    const response = await ApiClient.createSnippet(text, tags, getCredentials);
    const snippet = response.data;
    view.snippet = snippet;
    view.type = 'snippet';
    view.isBusy = false;
    view.name = buildNameFromSnippet(snippet);
    updateSnippetInSnippets(snippets, snippet);
    dispatch(mergeState({ views, snippets, status: Factory.newStatus(true, 'Created Snippet', false) }));

    fetchTags(getCurrentState, actions, dispatch, getCredentials); // TODO not sure if this needs await or some other sync/coordination
  } catch (ex) {
    view.isBusy = false;
    dispatch(mergeState({ views, status: Factory.newStatus(true, ex.message, true) }));
  }
}

async function updateSnippet(
  viewId,
  text,
  tags,
  dontUpdateTimestamp = false,
  getCurrentState,
  actions,
  dispatch,
  getCredentials
) {
  const { views, snippets } = getCurrentState();
  const view = findView(views, viewId);
  try {
    if (view.type !== 'snippet') return;

    view.isBusy = true;
    dispatch(mergeState({ views }));

    const { snippet_id } = view.snippet;
    const response = await ApiClient.updateSnippet(snippet_id, text, tags, dontUpdateTimestamp, getCredentials);
    const snippet = { ...view.snippet, ...response.data };
    view.snippet = snippet;
    view.isBusy = false;
    updateSnippetInSnippets(snippets, snippet);
    dispatch(mergeState({ views, snippets, status: Factory.newStatus(true, 'Updated Snippet', false) }));

    fetchTags(getCurrentState, actions, dispatch, getCredentials);
  } catch (ex) {
    view.isBusy = false;
    dispatch(mergeState({ views, status: Factory.newStatus(true, ex.message, true) }));
  }
}

function buildViewForSearch(search) {
  return Factory.newView(shortid.generate(), 'search', 'search', {
    search,
    snippets: [],
    stats: { loaded: 0, total: 0 }
  });
}

function startNewSearch(state) {
  return { ...state, views: addNewView(buildViewForSearch(Factory.newSearch()), state) };
}

const inInvalidFilter = (view, filterName) => view.search[filterName] === undefined;

const isSearchOrVirtualPage = (view) => view.type === 'search' || view.type === 'virtual-page';

function updateSearch(viewId, filterName, filterValue, state) {
  const { views } = state;
  const view = findView(views, viewId);
  if (!view || !isSearchOrVirtualPage(view) || inInvalidFilter(view, filterName)) return state;
  view.search[filterName] = filterValue;
  return { ...state, views };
}

async function deleteSnippet(viewId, getCurrentState, actions, dispatch, getCredentials) {
  const { views, snippets } = getCurrentState();
  const index = findViewIndex(views, viewId);
  const view = views[index];
  try {
    if (!view || view.type !== 'snippet') return;

    view.isBusy = true;
    dispatch(mergeState({ views }));

    const { snippet_id } = view.snippet;
    await ApiClient.deleteSnippet(snippet_id, getCredentials);
    delete snippets[snippet_id];

    views.splice(index, 1);

    dispatch(mergeState({ views, snippets, status: Factory.newStatus(true, 'Deleted Snippet', false) }));
  } catch (ex) {
    view.isBusy = false;
    dispatch(mergeState({ views, status: Factory.newStatus(true, ex.message, true) }));
    actions.setStatus(ex.message, true);
  }
}

async function buildViewForSnippet(state, snippetId, getCredentials) {
  let snippet = state.snippets[snippetId];
  if (!snippet) {
    snippet = await ApiClient.fetchSnippet(snippetId, getCredentials);
    if (!snippet) return null;
    updateSnippetInSnippets(state.snippets, snippet);
  }
  return Factory.newView(shortid.generate(), buildNameFromSnippet(snippet), 'snippet', { snippet });
}

async function openSnippetInView(snippetId, getCurrentState, actions, dispatch, getCredentials) {
  const state = getCurrentState();
  const newView = await buildViewForSnippet(state, snippetId, getCredentials);
  if (!newView) return;
  const views = addNewView(newView, state);
  dispatch(mergeState({ views, snippets: state.snippets, currentViewId: newView.viewId }));
}

function cancelNewSnippet(viewId, state) {
  const { views } = state;
  const index = findViewIndex(views, viewId);
  if (index < 0) return state;
  if (views[index].type !== 'new-snippet') return state;
  views.splice(index, 1);
  return { ...state, views };
}

function propagateSnippetEdits(viewId, edits, state) {
  const { views, snippets } = state;
  const newVersion = updateSnippetInSnippets(snippets, { ...snippets[edits.snippet_id], ...edits });
  views.forEach((view) => {
    if (view.viewId !== viewId) {
      if (view.type === 'snippet' && view.snippet.snippet_id === edits.snippet_id) {
        view.snippet = { ...view.snippet, ...edits, _version: newVersion };
      } else {
        const index = view.snippets.findIndex((s) => s.snippet_id === edits.snippet_id);
        if (index >= 0) view.snippets[index] = { ...view.snippets[index], ...edits, _version: newVersion };
      }
    }
  });
  return { ...state, views, snippets };
}

function loadSnippets(viewId, fetchedSnippets, stats, state) {
  const { views, snippets } = state;
  const view = findView(views, viewId);
  if (!view) throw new Error(`unknown view:${viewId}`);

  view.snippets = fetchedSnippets.map((s) => {
    const existing = snippets[s.snippet_id];
    return existing || s;
  });

  view.stats = stats;

  return { ...state, views };
}

function stringifyViewsForUrl(getCurrentState) {
  const { views } = getCurrentState();
  const supportedViews = {
    snippet: (view) => ({ snippet_id: view.snippet.snippet_id }),
    search: (view) => ({ search: view.search }),
    'virtual-page': (view) => ({ virtual_page_id: view.virtualPage.virtual_page_id })
  };

  const data = [];
  views.forEach((view) => {
    const { type } = view;
    if (supportedViews[type]) data.push({ type, ...supportedViews[type](view) });
  });
  return JSON.stringify(data);
}
async function loadViewsFromUrl(viewsUrlData, getCurrentState, actions, dispatch, getCredentials) {
  const state = getCurrentState();
  const { snippets } = state;
  const data = JSON.parse(viewsUrlData);
  const views = [];
  for (let i = 0; i < data.length; i++) {
    const v = data[i];
    let view;
    switch (v.type) {
      case 'snippet':
        view = await buildViewForSnippet(state, v.snippet_id, getCredentials);
        break;
      case 'search':
        view = buildViewForSearch(v.search);
        break;
      case 'virtual-page':
        view = await buildViewForVirtualPage(state, v.virtual_page_id, getCredentials);
        break;
      default:
        break; // TODO create an error, show status, something
    }
    if (view) views.push(view);
    dispatch(mergeState({ views, snippets }));
  }

  return { ...state, views };
}

function changeView(viewId, state) {
  if (findView(state.views, viewId)) return { ...state, currentViewId: viewId };

  return { ...state, currentViewId: null };
}
function closeView(viewId, state) {
  const { views } = state;
  const index = findViewIndex(views, viewId);
  if (index < 0) return state;
  views.splice(index, 1);
  return { ...state, views };
}

function swapView(viewId, boundary, offset, state) {
  const { views } = state;
  const index = findViewIndex(views, viewId);
  if (index === boundary) return state;
  const target = views[index];
  views[index] = views[index + offset];
  views[index + offset] = target;
  return { ...state, views };
}

function moveViewLeft(viewId, state) {
  return swapView(viewId, 0, -1, state);
}

function moveViewRight(viewId, state) {
  return swapView(viewId, state.views.length - 1, 1, state);
}

export function getLabelForFilterKey(options, itemId) {
  const option = options.find((opt) => opt.itemId === itemId);
  if (!option) return null;
  return option.label;
}
export function getKeyForFilterLabel(options, label) {
  const option = options.find((opt) => opt.label === label);
  if (!option) return null;
  return option.itemId;
}

// TODO Remove this hack
function stringifyReactiveSearchUrlParams(getCurrentState) {
  const { views } = getCurrentState();
  const params = [];
  views.forEach((view) => {
    if (view.search) {
      [{ name: 'updatedAt', options: ageFilters }].forEach((filter) => {
        const values = view.search[filter.name];
        if (values) {
          const labels = [];
          values.forEach((value) => {
            const label = getLabelForFilterKey(filter.options, value);
            if (label) labels.push(label);
          });
          if (labels.length > 0) params.push({ name: `${view.viewId}-${filter.name}`, value: JSON.stringify(labels) });
        }
      });

      ['types'].forEach((filter) => {
        const values = view.search[filter];
        if (values && values.length > 0) {
          params.push({ name: `${view.viewId}-${filter}`, value: JSON.stringify(values) });
        }
      });
    }
  });
  return params;
}

function createServicesForContext(getCurrentState, dispatch, getCredentials) {
  const conditions = {};

  const actions = {
    startNewSnippet: () => dispatch(actionFor(startNewSnippet)),
    cancelNewSnippet: (viewId) => dispatch(actionFor(cancelNewSnippet, viewId)),
    setUser: (user) => dispatch(mergeState({ user })),
    clearUser: () => dispatch(mergeState({ user: null })),
    setStatus: (msg, isError) => dispatch(mergeState({ status: Factory.newStatus(true, msg, isError) })),
    clearStatus: () => dispatch(mergeState({ status: Factory.newStatus(false, null, false) })),
    toggleDrawerOpen: () => dispatch(mergeState({ drawerOpen: !getCurrentState().drawerOpen })),
    setDrawerOpen: (val) => dispatch(mergeState({ drawerOpen: val })),
    startNewSearch: () => dispatch(actionFor(startNewSearch)),
    updateSearch: (viewId, filterName, filterValue) =>
      dispatch(actionFor(updateSearch, viewId, filterName, filterValue)),
    propagateSnippetEdits: (viewId, edits) => dispatch(actionFor(propagateSnippetEdits, viewId, edits)),
    loadSnippets: (viewId, snippets, stats) => dispatch(actionFor(loadSnippets, viewId, snippets, stats)),
    changeView: (viewId) => dispatch(actionFor(changeView, viewId)),
    closeView: (viewId) => dispatch(actionFor(closeView, viewId)),
    moveViewLeft: (viewId) => dispatch(actionFor(moveViewLeft, viewId)),
    moveViewRight: (viewId) => dispatch(actionFor(moveViewRight, viewId))
  };

  const effects = {
    createSnippet: async (viewId, text, tags) =>
      createSnippet(viewId, text, tags, getCurrentState, actions, dispatch, getCredentials),
    updateSnippet: async (viewId, text, tags, dontUpdateTimestamp) =>
      updateSnippet(viewId, text, tags, dontUpdateTimestamp, getCurrentState, actions, dispatch, getCredentials),
    deleteSnippet: async (viewId) => deleteSnippet(viewId, getCurrentState, actions, dispatch, getCredentials),
    loadViewsFromUrl: async (viewsUrlData) =>
      loadViewsFromUrl(viewsUrlData, getCurrentState, actions, dispatch, getCredentials),
    fetchTags: async () => fetchTags(getCurrentState, actions, dispatch, getCredentials),
    openSnippetInView: async (snippetId) =>
      openSnippetInView(snippetId, getCurrentState, actions, dispatch, getCredentials),
    openVirtualPageInView: async (virtualPageId) =>
      openVirtualPageInView(virtualPageId, getCurrentState, actions, dispatch, getCredentials),
    saveVirtualPage: async (viewId, name) =>
      saveVirtualPage(viewId, name, getCurrentState, actions, dispatch, getCredentials),
    fetchVirtualPages: async () => fetchVirtualPages(getCurrentState, actions, dispatch, getCredentials),
    deleteVirtualPage: async (viewId) => deleteVirtualPage(viewId, getCurrentState, actions, dispatch, getCredentials)
  };

  const utils = {
    getCredentials,
    stringifyReactiveSearchUrlParams: () => stringifyReactiveSearchUrlParams(getCurrentState),
    stringifyViewsForUrl: () => stringifyViewsForUrl(getCurrentState),
    get currentView() {
      const { views, currentViewId } = getCurrentState();
      const view = findView(views, currentViewId);
      if (view) return view;
      return views[0];
    }
  };

  return { effects, actions, conditions, utils };
}

export { reducer, createDefaultState, createServicesForContext };
