import aws4 from 'aws4';
import axios from 'axios';
import qs from 'qs';
import config from './config';

const ApiClient = {
  async signAwsRequest(request, path,  getCredentials ) {
    const { credentials } = await getCredentials();
    if (!credentials) {
      console.log('No credentials found for request signing!');
      return request;
    }

    const { accessKeyId, secretAccessKey, sessionToken } = credentials;
    const opts = {
      method: request.method,
      service: 'execute-api',
      region: config.awsConfig.Auth.region,
      path: `/${path}`,
      host: config.apiHost,
      headers: request.headers,
      url: `https://${config.apiHost}/${path}`
    };
    if (['POST', 'PUT', 'PATCH'].includes(request.method)) {
      opts.body = request.body;
      if (request.data) opts.data = request.data;
    }
    const signedRequest = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });
    delete signedRequest.headers.Host;
    delete signedRequest.headers['Content-Length'];
    return signedRequest;
  },

  async deleteSnippet(id, getCredentials) {
    const request = {
      method: 'DELETE'
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/snippets/${id}`, getCredentials);
    return axios(signedRequest);
  },

  async createSnippet(text, tags, getCredentials) {
    const data = { text, tags };
    const request = {
      method: 'POST',
      body: JSON.stringify(data),
      data,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };
    const signedRequest = await this.signAwsRequest(request, 'users/me/snippets', getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async fetchSnippet(id, getCredentials) {
    const request = {
      method: 'GET'
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/snippets/${id}`, getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async updateSnippet(id, text, tags, dontUpdateTimestamp = false, getCredentials) {
    const data = { text, tags };

    const queryString = qs.stringify({
      dont_update_timestamp: dontUpdateTimestamp
    });

    const request = {
      method: 'PUT',
      body: JSON.stringify(data),
      data,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/snippets/${id}?${queryString}`, getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async fetchSnippetsForExport(getCredentials) {
    // If headers are added to the get request, the AWS4 signature is invalid
    // no headers and it works. I have no idea why.

    const request = {
      method: 'GET'
    };
    const signedRequest = await this.signAwsRequest(request, 'users/me/snippets/actions.export', getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async fetchTags(getCredentials) {
    // If headers are added to the get request, the AWS4 signature is invalid
    // no headers and it works. I have no idea why.

    const request = {
      method: 'GET'
    };
    const signedRequest = await this.signAwsRequest(request, 'users/me/tags', getCredentials);
    const response = await axios(signedRequest);
    const tags = response.data.filter((t) => t.name.trim().length > 0);
    tags.sort((a, b) => a.name.localeCompare(b.name, { sensitivity: 'base' }));
    return tags;
  },

  async deleteVirtualPage(id, getCredentials) {
    const request = {
      method: 'DELETE'
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/virtual-pages/${id}`, getCredentials);
    return axios(signedRequest);
  },

  async createVirtualPage(name, type, search, getCredentials) {
    const data = { name, type, ...search };
    const request = {
      method: 'POST',
      body: JSON.stringify(data),
      data,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };
    const signedRequest = await this.signAwsRequest(request, 'users/me/virtual-pages', getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async fetchVirtualPage(id, getCredentials) {
    const request = {
      method: 'GET'
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/virtual-pages/${id}`, getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async  fetchVirtualPages(getCredentials) {
    const request = {
      method: 'GET'
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/virtual-pages`, getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  },

  async updateVirtualPage(id, virtualPage, search, getCredentials) {
    const data = {...virtualPage, ...search};

    const request = {
      method: 'PUT',
      body: JSON.stringify(data),
      data,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };
    const signedRequest = await this.signAwsRequest(request, `users/me/virtual-pages/${id}`, getCredentials);
    const response = await axios(signedRequest);
    return response.data;
  }
};

export default ApiClient;
