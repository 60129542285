import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useMount } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import ReactiveBase from '@appbaseio/reactivesearch/lib/components/basic/ReactiveBase';
import DrawerMenu from './DrawerMenu';
import ApiClient from '../services/api-client';
import { KeyboardProvider } from './KeyboardContext';
import View from './View';
import { DomainModelProvider, useDomainModel } from '../services/ModelContext';
import NavigationMenu from './NavigationMenu';
import ViewTabs from './ViewTabs';

const useStyles = makeStyles((theme) => ({
  contentFrame: {
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  frame: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    backgroundColor: theme.palette.custom.background
  }
}));

function AppContent() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { state, services } = useDomainModel();
  const location = useLocation();
  const history = useHistory();
  const viewsParam = services.utils.stringifyViewsForUrl();

  const onViewChange = (event, viewId) => services.actions.changeView(viewId);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  useMount(async () => {
    await services.effects.fetchVirtualPages();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const views = params.views ? JSON.parse(params.views) : [];
    if (views.length > 0) await services.effects.loadViewsFromUrl(params.views);
    else services.actions.startNewSearch();
    await services.effects.fetchTags();
  });

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    Object.keys(params).forEach((key) => {
      if (key !== 'views') {
        const parts = key.split(/-(.+)/);
        if (parts[0] && !state.views.find((v) => v.viewId === parts[0])) delete params[key];
      }
    });
    params.views = viewsParam;

    const rsParams = services.utils.stringifyReactiveSearchUrlParams();
    if (rsParams.length > 0) {
      rsParams.forEach((p) => {
        params[p.name] = p.value;
      });
    }
    history.push(`/?${qs.stringify(params)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, viewsParam, window.location.search]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={state.status.isOpen}
        onClose={services.actions.clearStatus}
        autoHideDuration={3000}
      >
        <Alert severity={state.status.isError ? 'error' : 'success'}>{state.status.msg}</Alert>
      </Snackbar>
      <div className={classes.frame}>
        <DrawerMenu open={drawerOpen}>
          <NavigationMenu toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
        </DrawerMenu>
        <div className={classes.contentFrame}>
          <ViewTabs onViewChange={onViewChange} />
          <Divider />
          {state.views.map((view) => (
            <div hidden={view.viewId !== services.utils.currentView.viewId} key={view.viewId}>
              <View view={view} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
function AppBase({ user, getCredentials, config }) {
  async function signRequests(request) {
    return ApiClient.signAwsRequest(request, `${user.reactiveSearchApp}/_msearch`, getCredentials);
  }

  return (
    <DomainModelProvider overrides={{ user }} getCredentials={getCredentials}>
      <ReactiveBase app={user.reactiveSearchApp} url={config.apiBaseUrl} transformRequest={signRequests}>
        <KeyboardProvider>
          <AppContent />
        </KeyboardProvider>
      </ReactiveBase>
    </DomainModelProvider>
  );
}

export default AppBase;
