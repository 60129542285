import React, { useRef, useState } from 'react';
import lodash from 'lodash';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';
import { makeStyles } from '@material-ui/core/styles';
import MarkdownEditor from './MarkdownEditor';
import MetaInfo from './MetaInfo';
import TimestampAge from './TimestampAge';
import { colors } from './style/colors';
import editorTheme from './style/editor-theme';
import { useDomainModel } from '../services/ModelContext';

function clearHeadingPseudoElements() {
  const styles = {};
  ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach((h) => {
    styles[`& ${h}:not(.placeholder):before`] = {
      content: 'none'
    };

    styles[`& ${h}:hover:before`] = {
      content: `"${h.toUpperCase()}"`,
      lineHeight: '2.5em'
    };
  });
  return styles;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridGap: 0,
    gridTemplateColumns: '80px 1fr',
    '& code': {
      backgroundColor: colors.tailwind.gray[300]
    },
    '& h1': {
      fontSize: '1.5em',
      '& .heading-name': {
        fontWeight: 700
      },
      '&:first-child': {
        marginTop: 0
      }
    },
    ...clearHeadingPseudoElements()
  },
  idButton: {
    visibility: 'hidden',
    padding: 4
  },
  timestampFrame: {
    alignSelf: 'end',
    paddingBottom: 8,
    '&:hover': {
      '& $idButton': {
        visibility: 'visible'
      }
    }
  },
  editorFrame: {
    maxHeight: 28 * 6, // line approx
    overflowY: 'scroll'
  },
  dividerFrame: {
    gridColumn: '1 / -1'
  },
  divider: {
    margin: '8px auto 16px auto',
    borderTop: `1px solid ${colors.tailwind.gray[300]}`,
    borderBottomStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none'
  }
}));

function Snippet({ snippet, tagOptions, saveSnippet, deleteSnippet, isCollapsed = false }) {
  const classes = useStyles();
  const { services } = useDomainModel();
  const editorRef = useRef(null);
  const [currentText, setCurrentText] = useState(snippet ? snippet.text : '');
  const [currentTags, setTags] = useState(snippet ? snippet.tags : []);

  const updateText = lodash.debounce((text) => {
    setCurrentText(text);
  }, 250);

  const onSave = (dontUpdateTimestamp) =>
    saveSnippet(snippet.snippet_id, currentText, currentTags, dontUpdateTimestamp);
  const onEditorSave = () => saveSnippet(snippet.snippet_id, currentText, currentTags, false);
  const onTagsChange = (newTags) => setTags(newTags);

  function copySelection() {
    if (editorRef.current) {
      const selection = editorRef.current.getSelection();
      copy(selection);
      services.actions.setStatus(`Copied selection markdown.`);
    }
  }

  const onFocus = () => mousetrap.bindGlobal('command+alt+k', copySelection);
  const onBlur = () => mousetrap.unbind('command+alt+k');

  if (!snippet) return null;

  const { snippet_id, _version: version } = snippet;

  return (
    <div className={classes.root}>
      <div className={classes.timestampFrame}>
        <TimestampAge snippet={snippet} />
      </div>
      <MetaInfo
        snippet={snippet}
        edit
        onTagsChange={onTagsChange}
        currentTags={currentTags}
        tagOptions={tagOptions}
        deleteSnippet={deleteSnippet}
        saveSnippet={onSave}
        getSelection={copySelection}
      />
      <div />
      <div className={clsx({ [classes.editorFrame]: isCollapsed })}>
        <MarkdownEditor
          ref={editorRef}
          onSave={onEditorSave}
          onChange={updateText}
          defaultValue={snippet.text}
          id={`${snippet_id}-${version}`}
          theme={editorTheme}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      <div className={classes.dividerFrame}>
        <hr className={classes.divider} />
      </div>
    </div>
  );
}
export default Snippet;
