import React, { forwardRef } from 'react';
import Editor from 'rich-markdown-editor';

const MarkdownEditor = forwardRef((props, ref) => {
  const { onChange, onSave, defaultValue, id, theme, onFocus, onBlur } = props;

  function onBlurWrapper(view, event) {
    console.log('blur', view, event);
    if (onBlur) onBlur();
  }

  return (
    <Editor
      ref={ref}
      theme={theme}
      onChange={onChange}
      onSave={onSave}
      defaultValue={defaultValue}
      id={id}
      key={id}
      uploadImage={(file) => {
        console.log('File upload triggered: ', file);

        // Delay to simulate time taken to upload
        return new Promise((resolve) => {
          setTimeout(() => resolve('https://loremflickr.com/1000/1000'), 1500);
        });
      }}
      handleDOMEvents={{
        focus: (view) => {
          console.log('FOCUS', view, ref, ref.current ? view === ref.current.view : false);
          if (onFocus) onFocus();
        },
        blur: onBlurWrapper,
        paste: (view, event) => console.log('PASTE', view, event),
        touchstart: (view, event) => console.log('TOUCH START', view, event)
      }}
    />
  );
});

export default MarkdownEditor;
