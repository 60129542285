import React, { createContext, useContext, useRef } from 'react';
import { useMount } from 'react-use';
import mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';

const KeyboardContext = createContext();

function KeyboardProvider({ children }) {
  const refs = useRef({});
  refs.current.search = useRef();
  refs.current.tags = useRef();
  refs.current.editor = useRef();

  function refFor(key) {
    return refs.current[key];
  }

  function makeFocusHandler(name) {
    return () => {
      const ref = refs.current[name];
      if (ref.current && ref.current.focus) ref.current.focus();
    };
  }
  useMount(() => {
    mousetrap.bindGlobal('ctrl+shift+s', makeFocusHandler('search'));
    mousetrap.bindGlobal('ctrl+shift+t', makeFocusHandler('tags'));
    mousetrap.bindGlobal('ctrl+shift+e', makeFocusHandler('editor'));
  });

  return <KeyboardContext.Provider value={{ refs, refFor }}>{children}</KeyboardContext.Provider>;
}

function useKeyboardManager() {
  return useContext(KeyboardContext);
}

export { KeyboardProvider, useKeyboardManager };
