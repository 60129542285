import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { colors } from './style/colors';
import TagsSelector from './TagsSelector';
import SnippetButtons from './SnippetButtons';
import TimestampAge from './TimestampAge';

const useStyles = makeStyles(() => ({
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  tag: {
    margin: '0 8px 8px 0',
    borderRadius: 8,
    backgroundColor: colors.custom.blueGray[300]
  },
  frame: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 0
  },
  tagsTextField: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: colors.tailwind.gray[300],
      borderBottomWidth: 1
    }
  }
}));

function TagsList({ snippet, size }) {
  const classes = useStyles();
  return (
    <div className={classes.tags}>
      {snippet.tags.map((t) => (
        <Chip key={t} label={t} classes={{ root: classes.tag }} size={size} />
      ))}
    </div>
  );
}

export default function MetaInfo({
  snippet,
  currentTags,
  size = 'medium',
  edit,
  onTagsChange,
  tagOptions,
  deleteSnippet,
  saveSnippet
}) {
  const classes = useStyles();

  return (
    <div className={classes.frame}>
      {!edit && <TagsList snippet={snippet} size={size} />}
      {edit && (
        <TagsSelector
          snippet={snippet}
          selected={currentTags}
          onChange={onTagsChange}
          allowCreate
          options={tagOptions}
          size="medium"
          variant="standard"
          textFieldClass={classes.tagsTextField}
        />
      )}
      {!edit && <TimestampAge snippet={snippet} />}
      {edit && <SnippetButtons snippet={snippet} deleteSnippet={deleteSnippet} saveSnippet={saveSnippet} />}
    </div>
  );
}
