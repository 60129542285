import moment from 'moment';
import Amplify, { Auth, Hub } from 'aws-amplify';
import config from './config';

let isAmplifyConfigured = false;

function buildUserStruct() {
  return {
    authenticated: false,
    attributes: {},
    username: null,
    credentials: {},
    reactiveSearchApp: config.reactiveSearch.appGuest
  };
}

async function checkAuthentication() {
  // according to amplify docs, try/catch to detected authentication
  // https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user

  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
}

async function checkAndLoadCredentials(authenticationCallback) {
  try {
    const user = buildUserStruct(config);
    const authenticated = await checkAuthentication();
    if (authenticated) {
      user.authenticated = true;
      user.reactiveSearchApp = config.reactiveSearch.appUser;
      user.credentials = await Auth.currentCredentials();
      const info = await Auth.currentUserInfo();
      user.username = info.username;
      user.attributes = info.attributes;
    }
    authenticationCallback(user);
  } catch (ex) {
    console.error(ex);
    authenticationCallback(buildUserStruct());
  }
}

function startRefreshTimer(onRefresh) {
  const interval = 10 * 60 * 1000; // 10 min
  async function refresh() {
    await checkAndLoadCredentials(onRefresh);
  }
  return setInterval(refresh, interval);
}

async function getCredentials(user) {
  const diff = moment(user.credentials.expiration).diff(moment(), 's', true);
  if (diff > 10) return null; // greater than 10 seconds

  let newUser = null;
  const onRefresh = (updated) => {
    newUser = updated;
  };
  await checkAndLoadCredentials(onRefresh);
  return newUser;
}

function configureAwsAmplifyAndRegisterCallback(authenticationCallback) {
  async function handleAuth(capsule) {
    if (
      capsule.payload.event === 'configured' ||
      capsule.payload.event === 'cognitoHostedUI' ||
      capsule.payload.event === 'signIn'
    ) {
      await checkAndLoadCredentials(authenticationCallback);
    }
    if (capsule.payload.event === 'signOut') {
      authenticationCallback(buildUserStruct());
    }
  }
  if (!isAmplifyConfigured) {
    Hub.listen('auth', handleAuth);
    Amplify.configure(config.awsConfig);
    isAmplifyConfigured = true;
  }
}
function signIn() {
  window.location.assign(config.signInUrl);
}

async function signOut() {
  try {
    await Auth.signOut();
  } catch (err) {
    console.error(err);
  }
}

function unregisterAwsAmplify(refreshTimer) {
  if (refreshTimer) clearInterval(refreshTimer);

  Hub.remove('auth');
}

const auth = {
  buildUserStruct,
  configureAwsAmplifyAndRegisterCallback,
  signOut,
  signIn,
  unregisterAwsAmplify,
  startRefreshTimer,
  getCredentials
};
export default auth;
