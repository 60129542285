import React from 'react';
import moment from 'moment';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  timestamp: {}
}));


function formatRelativeTimestamp(timestamp) {
  const ts = moment(timestamp);
  const ageInDays = moment().diff(ts, 'days', true);
  if (ageInDays > 7) return ts.format('MM.DD.YY');
  return ts.fromNow(true);
}

export default function TimestampAge({ snippet }) {
  const classes = useStyles();
  return (
    <Typography variant="caption" color="textPrimary" className={classes.timestamp}>
      {formatRelativeTimestamp(snippet.updatedAt)}
    </Typography>
  );
}
