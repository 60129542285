import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { BsChevronContract, BsChevronExpand } from 'react-icons/bs';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { connect } from '@appbaseio/reactivesearch/lib/utils';

import { colors } from './style/colors';
import { Loading } from './Loading';
import TagsFilter from './TagsFilter';
import { useKeyboardManager } from './KeyboardContext';
import AgeFilter from './AgeFilter';
import MultiDataFilter from './MultiDataFilter';
import { reactFilterIds } from '../utils/filter-utils';
import { sortOptions } from '../services/constants';
import { useDomainModel } from '../services/ModelContext';
import QueryInput from './QueryInput';
import { ConfirmationDialog } from './ActionDialog';
import VirtualPageButtons from './VirtualPageButtons';

const useStyles = makeStyles((theme) => ({
  leftSpace: {
    marginLeft: 16
  },
  root: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
  },
  bottomMargin: {
    marginBottom: 16
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchFrame: {
    minWidth: 200,
    maxWidth: 400,
    flexGrow: 1
  },
  tag: {
    margin: '0 8px 8px 0',
    borderRadius: 8,
    backgroundColor: colors.custom.blueGray[300]
  },
  tagFilterFrame: {
    flexGrow: 1
  },
  sort: {
    width: 185,
    marginLeft: 16
  },
  spacer: {
    flexGrow: 1
  },
  viewSelectorFrame: {
    [theme.breakpoints.up('xl')]: {
      display: 'none'
    }
  }
}));

function TypesFilter({ view }) {
  const { search, viewId } = view;
  const { services } = useDomainModel();
  const onChange = (values) => {
    services.actions.updateSearch(viewId, 'types', values);
  };
  return (
    <MultiDataFilter
      componentId={`${viewId}-types`}
      dataField="types.raw"
      title="types ..."
      reactFilterIds={reactFilterIds(viewId)}
      showSearch={false}
      value={search.types}
      onChange={onChange}
    />
  );
}

function Sort({ view }) {
  const classes = useStyles();
  const { search, viewId } = view;
  const { services } = useDomainModel();
  function onSortChange(ev, selection) {
    services.actions.updateSearch(viewId, 'sortBy', selection);
  }

  return (
    <Autocomplete
      disableClearable
      size="medium"
      options={sortOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.itemId === value.itemId}
      onChange={onSortChange}
      value={search.sortBy}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      classes={{ root: clsx(classes.sort, classes.leftSpace) }}
    />
  );
}

function ResultStats({ isLoading, total, hits }) {
  const classes = useStyles();
  if (isLoading) {
    return <Loading isLoading />;
  }
  if (hits && total) {
    return (
      <Typography variant="body1" color="textPrimary" style={{ justifySelf: 'center' }} className={classes.leftSpace}>
        {`${hits}/${total} items`}
      </Typography>
    );
  }
  return null;
}

const mapStateToProps = (state, props) => {
  const { prefix } = props;
  const componentId = `${prefix}-searchResults`;
  return {
    isLoading: state.isLoading[componentId] || false,
    total: state.hits[componentId] && state.hits[componentId].total,
    hits: state.hits[componentId] && state.hits[componentId].hits && state.hits[componentId].hits.length
  };
};

const ConnectedResultStats = connect(mapStateToProps, null)((props) => <ResultStats {...props} />);

function ViewToggle({ isCollapsed, setCollapsed }) {
  function handleToggle(event, value) {
    setCollapsed(value === 'collapsed');
  }
  return (
    <ToggleButtonGroup value={isCollapsed ? 'collapsed' : 'expanded'} exclusive onChange={handleToggle}>
      <ToggleButton value="collapsed" aria-label="collapsed">
        <BsChevronContract />
      </ToggleButton>
      <ToggleButton value="expanded" aria-label="expanded">
        <BsChevronExpand />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function FiltersAndSearch({ setCollapsed, isCollapsed, view }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [name, setName] = useState(view.name);
  const { viewId } = view;
  const classes = useStyles();
  const { refFor } = useKeyboardManager();
  const { services } = useDomainModel();

  const saveVirtualPage = () => services.effects.saveVirtualPage(viewId, name);
  const deleteVirtualPage = () => services.effects.deleteVirtualPage(viewId);

  function confirmDelete() {
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  function onNameChange(event) {
    setName(event.target.value);
  }

  return (
    <>
      <ConfirmationDialog
        anchorEl={null}
        isOpen={dialogOpen}
        closeHandler={closeDialog}
        message="Are you sure you want to delete the virtual page?"
        title="Delete Virtual Page"
        confirmButtonLabel="Yes, Delete"
        actionHandler={deleteVirtualPage}
      />
      <div className={classes.root}>
        <div className={clsx(classes.row, classes.bottomMargin)}>
          <div className={classes.searchFrame}>
            <QueryInput ref={refFor('search')} view={view} />
          </div>
          <div className={classes.spacer} />
          <div className={clsx(classes.viewSelectorFrame, classes.leftSpace)}>
            <TextField onChange={onNameChange} value={name} variant="outlined" />
          </div>
          <div className={clsx(classes.viewSelectorFrame, classes.leftSpace)}>
            <VirtualPageButtons view={view} onDelete={confirmDelete} onSave={saveVirtualPage} />
          </div>
          <div className={clsx(classes.viewSelectorFrame, classes.leftSpace)}>
            <ViewToggle setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
          </div>

          <ConnectedResultStats prefix={viewId} />
          <Sort view={view} />
        </div>
        <div className={classes.row}>
          <TagsFilter tagsInputRef={refFor('tags')} prefix={viewId} view={view} />
          <TypesFilter view={view} />
          <AgeFilter field="updatedAt" title="" prefix={viewId} view={view} />
        </div>
      </div>
    </>
  );
}
export default FiltersAndSearch;
