import { sortOptions } from './constants';

function newSearch(query, sortBy, tags, types, updatedAt) {
  return {
    query: query || '',
    sortBy: sortBy || sortOptions[1],
    tags: tags || [],
    types: types || [],
    updatedAt: updatedAt || []
  };
}

const Factory = {
  newSnippet: (text, tags, snippet_id) => ({ tags: tags || [], text: text || '', snippet_id }),
  newView: (viewId, name, type, rest) => ({ viewId, name, type, isBusy: false, ...rest }),
  newStatus: (isOpen, msg, isError) => ({ isOpen, msg, isError }),
  newVirtualPage: (virtual_page_id, name, type, rest) => ({ virtual_page_id, name, type, ...rest }),
  newSearch
};

export default Factory;
