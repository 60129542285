import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DrawerMenu from '../DrawerMenu';
import { sampleText } from '../../utils/mock-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.custom.background
  },
  contentFrame: {
    border: '1px solid #ababab',
    width: '100%',
    height: '100%'
  }
}));

function LeftSideBarMainPanel() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DrawerMenu open />
      <div className={classes.contentFrame}>
        {sampleText.split('\n').map((l) => (
          <Typography variant="body1">{l}</Typography>
        ))}
      </div>
    </div>
  );
}
export default LeftSideBarMainPanel;
