import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { VscAccount, VscAdd, VscNote, VscTag } from 'react-icons/vsc';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { Divider } from '@material-ui/core';
import UserMenu from './UserMenu';
import { useDomainModel } from '../services/ModelContext';

const useStyles = makeStyles(() => ({
  menuButton: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  hide: {
    display: 'none'
  },

  newIconButton: {
    margin: '16px 12px',
    padding: '5px 4px 5px 5px',
    fontSize: '1.5em',
    minWidth: 0,
    border: '2px solid',
    '&:hover': {
      border: '2px solid'
    }
  },
  newButton: {
    margin: '16px 12px',
    border: '2px solid',
    '&:hover': {
      border: '2px solid'
    }
  },
  icon: {
    fontSize: '1.5em'
  },
  frame: {
    display: 'flex',
    flexDirection: 'column'
  },
  spacer: {
    flexGrow: 1
  },
  logo: {
    margin: 20,
    width: 200
  }
}));

function Spacer() {
  const classes = useStyles();
  return <div className={classes.spacer} />;
}

function NewSnippetButton({ iconOnly, onClick }) {
  const classes = useStyles();
  if (iconOnly)
    return (
      <Button onClick={onClick} variant="outlined" color="primary" classes={{ root: classes.newIconButton }}>
        <VscAdd />
      </Button>
    );

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      color="primary"
      startIcon={<VscAdd />}
      classes={{ root: classes.newButton }}
    >
      New Snippet
    </Button>
  );
}

function VirtualPageItem({ virtualPage }) {
  const { name, virtual_page_id } = virtualPage;
  const { services } = useDomainModel();
  const onClick = () => services.effects.openVirtualPageInView(virtual_page_id);

  return (
    <ListItem button onClick={onClick}>
      <ListItemText primary={name} />
    </ListItem>
  );
}

export default function NavigationMenu({ toggleDrawer, drawerOpen }) {
  const classes = useStyles();
  const [menuUserAnchor, setUserMenuAnchor] = useState(null);

  const { state, services } = useDomainModel();
  const closeUserMenu = () => setUserMenuAnchor(null);

  const onNewSnippet = () => services.actions.startNewSnippet();
  const noOp = () => {};

  const menuItems = [
    {
      label: 'Account',
      icon: <VscAccount />,
      onClick: (event) => setUserMenuAnchor(event.currentTarget)
    },
    { label: 'Snippets', icon: <VscNote />, onClick: noOp },
    { label: 'Tags', icon: <VscTag />, onClick: noOp },
    { label: 'Virtual Pages', icon: <HiOutlineDocumentDuplicate />, onClick: noOp }
  ];

  return (
    <>
      <div>
        <div>
          <Link underline="none" href="/">
            <img className={classes.logo} src="/otherbrain-logo.svg" alt="OtherBrain" />
          </Link>
        </div>
        <NewSnippetButton iconOnly={!drawerOpen} onClick={onNewSnippet} />
        <List dense>
          <Divider />
          {menuItems.map((item, index) => (
            <ListItem button key={item.label} selected={index === 0} onClick={item.onClick}>
              <ListItemIcon classes={{ root: classes.icon }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
          {state.virtualPages.map((vp) => (
            <VirtualPageItem key={vp.name} virtualPage={vp} />
          ))}
        </List>
      </div>
      <Spacer />
      <div className={clsx(classes.menuButton)}>
        <Spacer />
        <IconButton classes={{ root: classes.icon }} aria-label="open drawer" onClick={toggleDrawer}>
          {drawerOpen ? <FiChevronsLeft /> : <FiChevronsRight />}
        </IconButton>
      </div>
      <UserMenu anchorEl={menuUserAnchor} onClose={closeUserMenu} getCredentials={services.utils.getCredentials} />
    </>
  );
}
