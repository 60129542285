import React, { useEffect, useRef, useState } from 'react';
import shortid from 'shortid';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from './Routes';
import { FullPageLoading } from './components/Loading';
import Auth from './services/auth';
import history from './services/history';
import theme from './components/style/theme';

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_.');

function Bootstrap() {
  const [isAppReady, setAppReady] = useState(false);
  const [user, setUser] = useState(Auth.buildUserStruct());
  const userRef = useRef(user);

  function authenticationCallback(authUser) {
    setUser(authUser);
    setAppReady(true);
  }

  async function getCredentials() {
    const newUser = await Auth.getCredentials(userRef.current);
    if (newUser) {
      console.log('refreshed user', user, userRef.current, newUser);
      userRef.current = newUser;
      setUser(newUser);
      return newUser;
    }
    return userRef.current;
  }

  useEffect(() => {
    Auth.configureAwsAmplifyAndRegisterCallback(authenticationCallback);
  }, []); // runs once

  if (isAppReady)
    return (
      <ThemeProvider theme={theme}>
        <Routes user={user} getCredentials={getCredentials} history={history} />
      </ThemeProvider>
    );

  return <FullPageLoading />;
}

export default Bootstrap;
