import React, { forwardRef, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { colors } from './style/colors';
import Tag from './Tag';

const useStyles = makeStyles((theme) => ({
  tag: {
    marginRight: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: colors.custom.blueGray[300],
    fontSize: '1em'
  },
  outlinedInput: {
    '&[class*="MuiOutlinedInput-root"]': {
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  outlinedInputPadding: {
    '&[class*="MuiOutlinedInput-root"]': {
      paddingLeft: 2,
      paddingTop: 12,
      paddingBottom: 11
    }
  },
  standardInput: {
    '& .MuiChip-deleteIcon': {
      display: 'none'
    }
  },
  standardTag: {
    marginBottom: theme.spacing(1)
  },
  tagFilterFrame: {
    flexGrow: 1
  }
}));

const TagsSelector = forwardRef((props, ref) => {
  const { selected, onChange, allowCreate, options, size, variant, textFieldClass } = props;
  const classes = useStyles();
  const inputRef = useRef();

  function onSelectionChange(event, selection) {
    onChange(selection.map((s) => (typeof s === 'string' ? s : s.name)));
  }

  if (ref) {
    ref.current = {
      focus: () => {
        if (inputRef.current) inputRef.current.focus();
      }
    };
  }

  const getOptionLabel = (option) => option.label;

  function renderTags(value, getCustomizedTagProps) {
    return value.map((option, index) => (
      <Tag label={getOptionLabel(option)} size={size} {...getCustomizedTagProps({ index })} />
    ));
  }

  const minOutlineSpace = variant === 'outlined' && selected.length > 0;
  const useOutlinePlaceholder = variant === 'outlined' && selected.length === 0;

  const placeholder = selected.length > 0 ? null : 'tags ...';

  return (
    <Autocomplete
      classes={{
        tag: clsx({ [classes.tag]: true, [classes.standardTag]: variant !== 'outlined' }),
        root: classes.tagFilterFrame,
        inputRoot: clsx({
          [classes.outlinedInput]: minOutlineSpace,
          [classes.standardInput]: variant !== 'outlined',
          [classes.outlinedInputPadding]: useOutlinePlaceholder
        })
      }}
      multiple
      clearOnBlur
      freeSolo={allowCreate}
      size={size}
      options={options}
      value={selected.map((s) => ({ name: s, count: 0, label: s }))}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.name === value.name}
      onChange={onSelectionChange}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          variant={variant}
          classes={{ root: textFieldClass }}
          placeholder={placeholder}
        />
      )}
    />
  );
});

export default TagsSelector;
