import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MultiDropdownRange } from '@appbaseio/reactivesearch';
import { filterDropDown } from './style/style-helper';
import { ageFilters } from '../services/constants';
import { useDomainModel } from '../services/ModelContext';
import { getLabelForFilterKey, getKeyForFilterLabel } from '../services/model';

const useStyles = makeStyles((theme) => filterDropDown(theme));

function RangeFilter({ title, field, id, data, placeholder, view }) {
  const classes = useStyles();
  const { search, viewId } = view;
  const { services } = useDomainModel();

  const currentSelection = search[field] ? search[field].map((v) => getLabelForFilterKey(data, v)) : null;

  const onChange = (values) => {
    const keys = values ? values.map((v) => getKeyForFilterLabel(data, v)) : null;
    services.actions.updateSearch(viewId, field, keys);
  };

  return (
    <MultiDropdownRange
      className={classes.selectWrapper}
      componentId={id}
      dataField={field}
      title={title}
      data={data}
      placeholder={placeholder}
      innerClass={{ select: classes.selectBox }}
      value={currentSelection}
      onChange={onChange}
      URLParams
    />
  );
}
export default function AgeFilter({ field, title, view }) {
  const { viewId } = view;
  return (
    <RangeFilter
      id={`${viewId}-${field}`}
      field={field}
      title={title}
      data={ageFilters}
      placeholder="Updated ..."
      view={view}
    />
  );
}
