import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerBackground: {
    backgroundColor: theme.palette.custom.background
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1
  },
  newIconButton: {
    margin: '16px 12px',
    padding: '6px 4px 6px 5px',
    fontSize: '1.5em',
    minWidth: 0,
    border: '2px solid',
    '&:hover': {
      border: '2px solid'
    }
  },
  newButton: {
    margin: '16px 12px',
    border: '2px solid',
    '&:hover': {
      border: '2px solid'
    }
  },
  icon: {
    fontSize: '2em'
  },
  frame: {
    display: 'flex',
    flexDirection: 'column'
  },
  spacer: {
    flexGrow: 1
  },
  logo: {
    margin: 20,
    width: 200
  }
}));

export default function DrawerMenu({ open = false, children }) {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerBackground]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      {children}
    </Drawer>
  );
}
