import React from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';
import config from './services/config';
import App from './components/App';
import { SignIn, SignOut, SignInCallback, AuthenticatedRoute } from './components/Auth';
import LeftSideBarMainPanel from './components/layouts/LeftSideBarMainPanel';

function ShowSignLink() {
  return <Link to={config.routes.signIn}>Sign in</Link>;
}

function Routes({ user, getCredentials, history }) {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/test" component={LeftSideBarMainPanel} />
        <Route path={config.routes.signIn} component={SignIn} />
        <Route path={config.routes.signOut} component={SignOut} />
        <Route path={config.routes.authenticatedCallback} render={() => <SignInCallback user={user} />} />
        <Route path={config.routes.signedOutCallback} render={() => <ShowSignLink />} />
        <AuthenticatedRoute
          user={user}
          path={config.routes.home}
          render={() => <App user={user} getCredentials={getCredentials} config={config} />}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
