import React from 'react';
import SnippetView from './SnippetView';
import SearchView from './SearchView';

function View({ view }) {
  switch (view.type) {
    case 'new-snippet':
      return <SnippetView isNew view={view} />;
    case 'snippet':
      return <SnippetView view={view} />;
    case 'search':
      return <SearchView view={view} />;
    case 'virtual-page':
      return <SearchView view={view} />;
    default:
      return null;
  }
}

export default View;
