import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { VscSave, VscSaveAs, VscTrash, VscFileSymlinkFile, VscMarkdown } from 'react-icons/vsc';
import { FiHash } from 'react-icons/fi';
import copy from 'copy-to-clipboard';
import { useDomainModel } from '../services/ModelContext';

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: theme.spacing(0.75)
  }
}));

export default function SnippetButtons({ snippet, deleteSnippet, saveSnippet = () => {} }) {
  const classes = useStyles();
  const { services } = useDomainModel();
  function onDelete() {
    deleteSnippet(snippet.snippet_id);
  }
  const onSave = () => saveSnippet(false);
  const onSaveWithoutUpdatingTimestamp = () => saveSnippet(true);
  const onOpenSnippet = () => services.effects.openSnippetInView(snippet.snippet_id);
  function onIdClick() {
    copy(snippet.snippet_id);
    services.actions.setStatus(`Copied #${snippet.snippet_id}`);
  }
  function onMdClick() {
    copy(snippet.text);
    services.actions.setStatus(`Copied markdown`);
  }

  return (
    <>
      <IconButton classes={{ root: classes.icon }} aria-label="open snippet" onClick={onOpenSnippet}>
        <VscFileSymlinkFile />
      </IconButton>
      <IconButton classes={{ root: classes.icon }} aria-label="copy snippet id" onClick={onIdClick}>
        <FiHash />
      </IconButton>
      <IconButton classes={{ root: classes.icon }} aria-label="copy markdown" onClick={onMdClick}>
        <VscMarkdown />
      </IconButton>
      <IconButton classes={{ root: classes.icon }} aria-label="save snippet" onClick={onSave}>
        <VscSave />
      </IconButton>
      {onSaveWithoutUpdatingTimestamp && (
        <IconButton classes={{ root: classes.icon }} aria-label="save snippet" onClick={onSaveWithoutUpdatingTimestamp}>
          <VscSaveAs />
        </IconButton>
      )}
      <IconButton classes={{ root: classes.icon }} aria-label="delete snippet" onClick={onDelete}>
        <VscTrash />
      </IconButton>
    </>
  );
}
