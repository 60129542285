import React from 'react';
import Chip from '@material-ui/core/Chip';

const palette = [
  '#ffc9c9',
  '#bac8ff',
  '#eebefa',
  '#e9ecef',
  '#d0bfff',
  '#fcc2d7',
  '#a5d8ff',
  '#99e9f2',
  '#96f2d7',
  '#ffd8a8',
  '#d8f5a2',
  '#ffec99',
  '#b2f2bb'
];

// from https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
function hashCode(s) {
  let h;
  for (let i = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

  return h;
}

function Tag({ label, size, ...rest }) {
  const color = palette[Math.abs(hashCode(label)) % palette.length];
  return <Chip style={{ backgroundColor: color }} label={label} size={size} {...rest} />;
}
export default Tag;
