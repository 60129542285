import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactiveList } from '@appbaseio/reactivesearch';
import { makeStyles } from '@material-ui/core/styles';
import Snippet from './Snippet';
import apiClient from '../services/api-client';
import { ConfirmationDialog } from './ActionDialog';
import { reactFilterIds } from '../utils/filter-utils';
import { useDomainModel } from '../services/ModelContext';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 900,
    margin: '16px auto'
  },
  sortDropdown: {
    display: 'none'
  }
}));

function Snippets({ showStatus, tagOptions, view, isCollapsed }) {
  const { viewId, search } = view;
  const classes = useStyles();
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [targetSnippetId, setTargetSnippetId] = useState();
  const { services } = useDomainModel();
  const { getCredentials } = services.utils;

  async function saveSnippet(id, text, tags, dontUpdateTimestamp) {
    try {
      const response = await apiClient.updateSnippet(id, text, tags, dontUpdateTimestamp, getCredentials);
      if (response.statusCode !== 200) throw new Error(response.statusText);
      showStatus('Updated Snippet');
    } catch (ex) {
      showStatus(ex.message, true);
    }
  }
  async function deleteSnippet() {
    try {
      await apiClient.deleteSnippet(targetSnippetId, getCredentials);
      showStatus('Deleted Snippet');
      setDialogOpen(false);
      setTargetSnippetId(null);
      history.go(0);
    } catch (ex) {
      showStatus(ex.message);
    }
  }
  function confirmDelete(id) {
    setTargetSnippetId(id);
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  return (
    <>
      <ConfirmationDialog
        anchorEl={null}
        isOpen={dialogOpen}
        closeHandler={closeDialog}
        message="Are you sure you want to delete the snippet?"
        title="Delete Snippet"
        confirmButtonLabel="Yes, Delete"
        actionHandler={deleteSnippet}
      />
      <ReactiveList
        componentId={`${viewId}-searchResults`}
        dataField="updatedAt"
        react={{
          and: reactFilterIds(viewId)
        }}
        scrollTarget={`${viewId}-editors-list`}
        infiniteScroll
        sortOptions={[search.sortBy]}
        defaultSortOption={search.sortBy.label}
        showResultStats={false}
        innerClass={{ sortOptions: classes.sortDropdown }}
        onData={({ data, resultStats }) => {
          services.actions.loadSnippets(viewId, data, {
            total: resultStats.numberOfResults,
            loaded: resultStats.displayedResults
          });
        }}
        defaultQuery={() => ({ version: true })}
        render={() => (
          <ReactiveList.ResultListWrapper>
            {view.snippets.map((snippet) => {
              return (
                <div key={snippet.snippet_id}>
                  <Snippet
                    snippet={snippet}
                    showStatus={showStatus}
                    tagOptions={tagOptions}
                    saveSnippet={saveSnippet}
                    deleteSnippet={confirmDelete}
                    isCollapsed={isCollapsed}
                  />
                </div>
              );
            })}
          </ReactiveList.ResultListWrapper>
        )}
      />
    </>
  );
}
export default Snippets;
