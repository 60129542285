import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import FiltersAndSearch from './FiltersAndSearch';
import Snippets from './Snippets';
import { colors } from './style/colors';
import { useDomainModel } from '../services/ModelContext';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 0,
    height: '100vh',
    width: '100%',
    display: 'grid',
    gridGap: 0,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'min-content min-content'
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'minmax(300px, 400px) minmax(600px, 1fr)'
    },
    gridTemplateRows: 'auto 1fr'
  },
  listFrame: {
    overflowY: 'scroll',
    transition: 'width 0.6s',
    backgroundColor: colors.tailwind.gray['200']
  },
  editorGridFrame: {
    overflowY: 'scroll',
    transition: 'width 0.6s',
    borderLeft: `solid 1px #cdcdcd`
  },
  editorFrame: {
    padding: '16px 16px'
  },

  open: {
    [theme.breakpoints.down('lg')]: {
      width: 'calc(100vw - 56px)'
    }
  },
  closed: {
    [theme.breakpoints.down('lg')]: {
      width: 0
    },
    [theme.breakpoints.up('xl')]: {
      width: 'auto'
    }
  },
  filterFrame: {
    gridColumn: '1 / -1',
    borderBottom: `solid 1px #cdcdcd`
  },
  logo: {
    width: 175
  },
  editorList: {
    maxWidth: 1100,
    margin: '16px auto'
  }
}));

function SearchView({ view }) {
  const classes = useStyles();
  const { state, services } = useDomainModel();
  const [isCollapsed, setCollapsed] = useState(false);
  // const { breakpoints } = useTheme();
  // const useSinglePanel = useMediaQuery(`(max-width:${breakpoints.values.xl}px)`);

  return (
    <div className={classes.grid}>
      <div className={classes.filterFrame}>
        <FiltersAndSearch user={state.user} isCollapsed={isCollapsed} setCollapsed={setCollapsed} view={view} />
      </div>
      <div
        id={`${view.viewId}-editors-list`}
        className={clsx(classes.drawer, {
          [classes.editorGridFrame]: true,
          [classes.open]: true
        })}
      >
        <div className={classes.editorFrame}>
          <div className={classes.editorList}>
            <Snippets
              user={state.user}
              isCollapsed={isCollapsed}
              showStatus={services.actions.setStatus}
              tagOptions={state.tags}
              view={view}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchView;
