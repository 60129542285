import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { FullPageLoading } from './Loading';
import config from '../services/config';
import Auth from '../services/auth';

export function RedirectSignIn() {
  console.log('redirect to signin');
  return (
    <Redirect
      to={{
        pathname: config.routes.signIn
      }}
    />
  );
}

export function AuthenticatedRoute({ user, render, location, ...rest }) {
  if (user.authenticated) {
    return <Route exact {...rest} render={render} />;
  }
  return (
    <Redirect
      to={{
        pathname: config.routes.signIn,
        state: { location }
      }}
    />
  );
}

export function SignIn() {
  useEffect(() => {
    Auth.signIn();
  });
  return <FullPageLoading />;
}

export function SignInCallback({ user, location }) {
  if (user.authenticated) {
    return (
      <Redirect
        to={{
          pathname: config.routes.home,
          state: { location }
        }}
      />
    );
  }
  return <FullPageLoading />;
}

export function SignOut() {
  useEffect(() => {
    Auth.signOut();
  });
  return <FullPageLoading />;
}
