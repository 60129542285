import lodash from 'lodash';
import { colors } from './colors';

/** @module utility-styles */

function flexContainer(direction, align, justify, wrap) {
  const style = {
    display: 'flex'
  };
  if (direction) style.flexDirection = direction;
  if (align) style.alignItems = align;
  if (justify) style.justifyContent = justify;
  if (wrap) style.flexWrap = wrap;

  return style;
}

export const flex = {
  row: (align, justify, wrap) => flexContainer('row', align, justify, wrap),
  col: (align, justify, wrap) => flexContainer('column', align, justify, wrap)
};

export function border(color = 'tailwind.gray', weight = 300, pixels = 1) {
  return `${pixels}px solid ${lodash.get(colors, color)[weight]}`;
}
/**
 * Builds border radius css properties.
 * @param {(number|string)} args - 1 arg is common radius, 2 args is left radius (TL, BL) and right radius (TR, BR), 4 args is TL, TR, BR, BL
 */
export function borderRadius(...args) {
  // inspired by material-ui style.spacing
  switch (args.length) {
    case 1:
      return { borderRadius: args[0] };
    case 2:
      return {
        borderBottomLeftRadius: args[0],
        borderTopLeftRadius: args[0],
        borderBottomRightRadius: args[1],
        borderTopRightRadius: args[1]
      };
    case 4:
      return {
        borderTopLeftRadius: args[0],
        borderTopRightRadius: args[1],
        borderBottomRightRadius: args[2],
        borderBottomLeftRadius: args[3]
      };
    default:
      throw new Error(`borderRadius: unexpected number of args. Expected 1, 2, 4 -- got ${args.length}`);
  }
}

export const filterDropDown = (theme) => ({
  selectBox: {},
  selectWrapper: {
    marginLeft: theme.spacing(2),
    width: 200,
    '& $selectBox': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.custom.background,
    }
  }
});
