import React, { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactiveComponent from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import InputBase from '@material-ui/core/InputBase';
import { colors } from './style/colors';
import { useDomainModel } from '../services/ModelContext';
import { reactFilterIds } from '../utils/filter-utils';

const useStyles = makeStyles((theme) => ({
  search: {
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px #ccc`,

    [theme.breakpoints.down('xs')]: {
      left: 0,
      position: 'relative',
      marginLeft: theme.spacing(2)
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    '&:focus': {
      boxShadow: `0 0 0 3px ${colors.custom.blue5.transparent[600]}`,
      backgroundColor: colors.white
    },
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 2, 1.5, 2),
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 38,

    [theme.breakpoints.up('sm')]: {
      width: '40ch'
    },
    [theme.breakpoints.down('sm')]: {
      width: '30ch'
    },
    [theme.breakpoints.down('xs')]: {
      width: '16ch'
    }
  }
}));

const QueryInput = forwardRef((props, ref) => {
  const classes = useStyles();
  const { view } = props;
  const { viewId, search } = view;
  const { services } = useDomainModel();
  const [terms, setTerms] = useState(search.query);
  const [lastTerms, setLastTerms] = useState(search.query);

  const react = reactFilterIds(viewId).filter((item) => item !== `${viewId}-terms`);
  const customQuery = (queryProps) => {
    if (queryProps) {
      const selectedValue = lastTerms;
      if (selectedValue && selectedValue.length > 0) {
        const query = {
          simple_query_string: {
            query: selectedValue,
            fields: ['text^6', 'tags^1'],
            default_operator: 'or'
          }
        };
        return { query };
      }
    }
    return null;
  };
  return (
    <ReactiveComponent
      componentId={`${viewId}-terms`}
      customQuery={customQuery}
      URLParams
      react={{
        and: react
      }}
      render={({ setQuery }) => {
        function updateQuery(event) {
          setTerms(event.target.value);
        }

        function executeQuery() {
          services.actions.updateSearch(view.viewId, 'query', terms);
          setLastTerms(terms);
          let query = null;
          if (terms && terms.length > 0)
            query = {
              simple_query_string: {
                query: terms,
                fields: ['text^6', 'tags^1'],
                default_operator: 'or'
              }
            };
          setQuery({ query, value: terms });
        }

        function handleKeyPress(event) {
          if (event.key === 'Enter') {
            executeQuery();
          }
        }

        return (
          <div className={classes.search}>
            <InputBase
              placeholder="Search snippets via text or tags..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              value={terms}
              onChange={updateQuery}
              onKeyPress={handleKeyPress}
              onBlur={executeQuery}
              type="search"
              inputProps={{ 'aria-label': 'search' }}
              ref={ref}
            />
          </div>
        );
      }}
    />
  );
});

export default QueryInput;
