import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { VscSave, VscTrash } from 'react-icons/vsc';
import RefreshSearchButton from './RefreshSearchButton';

export default function VirtualPageButtons({ view, onSave, onDelete }) {
  return (
    <>
      <RefreshSearchButton view={view} />
      <IconButton aria-label="save snippet" onClick={onSave}>
        <VscSave />
      </IconButton>
      <IconButton aria-label="delete snippet" onClick={onDelete}>
        <VscTrash />
      </IconButton>
    </>
  );
}
