import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { MdClose, MdAdd } from 'react-icons/md';
import {
  HiOutlineSearch,
  HiOutlineDocument,
  HiOutlineDocumentDuplicate,
  HiChevronLeft,
  HiChevronRight
} from 'react-icons/hi';
import clsx from 'clsx';
import { colors } from './style/colors';
import { useDomainModel } from '../services/ModelContext';

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: theme.spacing(0, 0, 0, 0.5),
    backgroundColor: theme.palette.custom.background,
    borderRight: `1px solid ${colors.tailwind.gray[400]}`
  },
  selected: {
    backgroundColor: theme.palette.custom.selectedTab
  },
  icon: {
    fontSize: '1em',
    padding: theme.spacing(0.5)
  },
  addTab: {
    fontSize: '1.25em'
  },
  orderButtons: {
    display: 'none'
  },
  tabFrame: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&:hover $orderButtons': {
      display: 'block'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }
}));

function TabIcon({ view }) {
  switch (view.type) {
    case 'new-snippet':
      return <HiOutlineDocument />;
    case 'snippet':
      return <HiOutlineDocument />;
    case 'search':
      return <HiOutlineSearch />;
    case 'virtual-page':
      return <HiOutlineDocumentDuplicate />;
    default:
      return null;
  }
}

function TabLabel({ view }) {
  const classes = useStyles();
  const { services } = useDomainModel();
  const onCloseView = () => services.actions.closeView(view.viewId);
  const onShiftLeft = () => services.actions.moveViewLeft(view.viewId);
  const onShiftRight = () => services.actions.moveViewRight(view.viewId);

  return (
    <div className={classes.tabFrame}>
      <TabIcon view={view} />
      <IconButton classes={{ root: classes.orderButtons }} onClick={onShiftLeft}>
        <HiChevronLeft />
      </IconButton>
      <div>{view.name}</div>
      <IconButton classes={{ root: classes.orderButtons }} onClick={onShiftRight}>
        <HiChevronRight />
      </IconButton>
      <IconButton classes={{ root: classes.icon }} onClick={onCloseView}>
        <MdClose />
      </IconButton>
    </div>
  );
}

function ViewTabs({ onViewChange }) {
  const classes = useStyles();
  const { state, services } = useDomainModel();
  const currentViewId = services.utils.currentView ? services.utils.currentView.viewId : null;
  const onAddClick = () => services.actions.startNewSearch();

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={services.utils.currentView ? services.utils.currentView.viewId : null}
        onChange={onViewChange}
        aria-label="Views"
        indicatorColor="primary"
        scrollButtons="auto"
      >
        {state.views.map((view) => (
          <Tab
            classes={{ root: clsx(classes.tab, { [classes.selected]: currentViewId === view.viewId }) }}
            component="div"
            label={<TabLabel view={view} />}
            value={view.viewId}
            key={view.viewId}
          />
        ))}
      </Tabs>
      <IconButton classes={{ root: classes.addTab }} onClick={onAddClick}>
        <MdAdd />
      </IconButton>
    </div>
  );
}
export default ViewTabs;
